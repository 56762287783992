import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signOut } from 'firebase/auth';
import { Buffer } from 'buffer';

const config = JSON.parse(Buffer.from(process.env.REACT_APP_FIREBASE_CONFIG || '', 'base64').toString('utf-8'));

const app = initializeApp(config);
const auth = {
  auth: getAuth(app),
  provider: new GoogleAuthProvider(),
  signout: signOut,
};
export { auth };
