import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAxiosPost } from '../hooks/usePost';
import EnterForm from '../components/EnterForm';
import { RegistUserResponse, CorrctResponse, BenefitCorrctDataResponse } from 'shared';
import Submited from '../components/Submited';
import BenefitForm from '../components/BenefitForm';

const App: React.FC<{}> = () => {
  const [isChecked, setChecked] = useState(false);
  const postCorrect = useAxiosPost<CorrctResponse>('/product/benefit-create');
  const postCheck = useAxiosPost<RegistUserResponse>('/product/check');
  const [reserveNumber, setReserveNumber] = useState('');

  const correct: BenefitCorrctDataResponse['data'] = useMemo(() => {
    if (postCheck.response?.result) {
      return {
        name: postCheck.response.data.name,
        email: postCheck.response.data.account,
        reserveNumber: reserveNumber,
      };
    } else {
      return undefined;
    }
  }, [postCheck.response]);

  const onSubmit = useCallback(
    (data: any) => {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key !== 'image') {
          formData.append(key, data[key]);
        } else {
          formData.append(key, data[key][0]);
        }
      });

      postCorrect.postFileData(formData);
    },
    [postCorrect.postFileData],
  );

  const onSubmitNumber = useCallback(
    (data: { [key: string]: string }) => {
      postCheck.postData(data);
      setReserveNumber(data.number);
    },
    [postCheck.postData],
  );

  useEffect(() => {
    if (postCheck.response) {
      setChecked(postCheck.response.result);
    }
  }, [postCheck.response]);

  if (!isChecked) {
    return <EnterForm onSubmit={onSubmitNumber} error={postCheck.response?.message} />;
  }
  if (['success', 'error'].includes(postCorrect.status)) {
    return <Submited status={postCorrect.status} editUrl={postCorrect.response?.editUrl || ''} />;
  }
  return <BenefitForm onSubmit={onSubmit} isLoading={postCorrect.status === 'loading'} correct={correct} />;
};

export default App;
