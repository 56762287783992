import { useState, useCallback } from 'react';
import useAxios from './useAxios';
import { isAxiosError } from 'axios';

export const useAxiosPost = <T>(url: string) => {
  const [status, setStatus] = useState('idle'); // idle, loading, success, error
  const [response, setResponse] = useState<T>();
  const [error, setError] = useState('');
  const { axiosClient, updateToken } = useAxios();

  const postData = useCallback(
    async (data: Object) => {
      setStatus('loading');
      setError('');

      try {
        const res = await axiosClient.post(url, data);
        setResponse(res.data);
        setStatus('success');
      } catch (err) {
        if (isAxiosError(err)) {
          setError(err.response?.data.message);
        } else {
          setError('エラーが発生しました');
        }

        setStatus('error');
      }
    },
    [url, axiosClient],
  );

  const postFileData = useCallback(
    async (data: Object) => {
      setStatus('loading');
      setError('');

      try {
        const res = await axiosClient.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } });
        setResponse(res.data);
        setStatus('success');
      } catch (err) {
        if (isAxiosError(err)) {
          setError(err.response?.data.message);
        } else {
          setError('エラーが発生しました');
        }

        setStatus('error');
      }
    },
    [url, axiosClient],
  );
  return { status, response, error, postData, postFileData, updateToken };
};
