import React from 'react';
import {
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Link,
} from '@mui/material';
import Textarea from '@mui/joy/Textarea';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { CorrctDataResponse } from 'shared';
interface Props {
  onSubmit: (data: any) => void;
  isLoading: boolean;
  correct: CorrctDataResponse['data'];
}
const App: React.FC<Props> = ({ onSubmit, correct, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Typography variant="h4" component="h4">
            リアルタイム添削応募フォーム
          </Typography>
          <Typography variant="body1">
            竹花ノートによるイラスト添削の申込みフォームです。 必要事項に回答の上お申し込みください。
            ご加入中のプランに関してpixivFANBOXの加入プランの状況がわかるスクショ画像を提出してください。 FANBOX→{' '}
            <Link href="https://take-note.fanbox.cc/">https://take-note.fanbox.cc/</Link>
          </Typography>
          <TextField
            required
            label="作者名"
            {...register('name', { required: '必須です', minLength: 1, value: correct?.name })}
          />
          <TextField
            required
            label="連絡先URL(twitterアカウント or PixivId)"
            {...register('account', { required: '必須です', minLength: 1, value: correct?.account })}
          />
          <FormControlLabel
            control={<Checkbox {...register('describe')} defaultChecked={correct && correct.describe === 'TRUE'} />}
            label="添削配信で名前とSNSの記載を希望する"
          />
          <TextField required label="作品タイトル" {...register('title', { value: correct?.title })} />
          <TextField label="作成時間" {...register('cost', { value: correct?.cost })} />
          <TextField label="作成ツール" {...register('tool', { value: correct?.tool })} />
          <Textarea
            minRows={3}
            placeholder="特に見てもらいたい点、うまくなりたい点など(最大200文字前後)"
            style={{ width: '100%' }}
            {...register('point', { value: correct?.point })}
          />
          <Textarea
            minRows={3}
            placeholder="コメント、目標など(最大200文字前後)"
            style={{ width: '100%' }}
            {...register('comment', { value: correct?.comment })}
          />
          <TextField label="目標の作家さん等" {...register('target', { value: correct?.target })} />

          <Typography variant="body1">ご加入中のプラン(10000円プランのみ）の加入状況がわかる画像</Typography>
          <input required {...register('card', { required: true })} type="file" accept=".png, .jpg, .jpeg" />
          <Typography variant="body1">添削希望のイラスト</Typography>
          <input
            required
            {...register('image', {
              required: '必須です',
              validate: (files) => (files[0]?.size > 5242880 ? '添削用の画像は5MB以内でお願いします。' : undefined),
            })}
            type="file"
            accept=".png, .jpg, .jpeg"
          />
          <ErrorMessage errors={errors} name="image" as="p" style={{ color: 'red' }} />
          <Button color="primary" variant="contained" size="large" type="submit" disabled={isLoading}>
            {isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary',
                }}
              />
            ) : (
              '送信'
            )}
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default App;
