import React from 'react';
import {
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Link,
} from '@mui/material';
import Textarea from '@mui/joy/Textarea';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { BenefitCorrctDataResponse } from 'shared';
interface Props {
  onSubmit: (data: any) => void;
  isLoading: boolean;
  correct: BenefitCorrctDataResponse['data'];
}
const App: React.FC<Props> = ({ onSubmit, correct, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Typography variant="h4" component="h4">
            継続特典がっつり添削応募フォーム
          </Typography>
          <Typography variant="body1">
            竹花ノートによるイラスト添削の申込みフォームです。 必要事項に回答の上お申し込みください。
            入力済みの名前とメールアドレスが異なっている場合や変更が必要な場合はお手数をお掛けしますが竹花塾Discodeサーバーか
            <Link href="https://twitter.com/29_Nom">こちら</Link>までお問い合わせください。
          </Typography>
          <Typography variant="body1">推奨画像サイズ2508px × 3541px 350dpi 150MB以内</Typography>
          <Typography variant="body1">
            ファイルサイズが大きい場合は画像サイズの調整やレイヤーの整理をお願いします。 レイヤーデータは 背景
            キャラクター エフェクト で分かれていると添削がしやすくなります。
          </Typography>
          <TextField
            disabled
            required
            defaultValue={correct?.name}
            label="作者名"
            {...register('name', { required: '必須です', minLength: 1 })}
          />
          <TextField
            disabled
            required
            defaultValue={correct?.email}
            label="メール"
            {...register('email', { required: '必須です', minLength: 1 })}
          />
          <TextField
            disabled
            required
            defaultValue={correct?.reserveNumber}
            label="受付番号"
            {...register('number', { required: '必須です', minLength: 1 })}
          />
          <TextField
            required
            label="連絡先URL(twitterアカウント or PixivId)"
            defaultValue={correct?.account}
            {...register('account', { required: '必須です', minLength: 1 })}
          />
          <FormControlLabel
            control={<Checkbox {...register('describe')} defaultChecked={correct && correct.describe === 'TRUE'} />}
            label="添削配信で名前とSNSの記載を希望する"
          />
          <TextField required label="作品タイトル" defaultValue={correct?.title} {...register('title')} />
          <TextField label="作成時間" defaultValue={correct?.cost} {...register('cost', { value: correct?.cost })} />
          <TextField label="作成ツール" {...register('tool', { value: correct?.cost })} />
          <Textarea
            minRows={3}
            placeholder="特に見てもらいたい点、うまくなりたい点など(最大200文字前後)"
            style={{ width: '100%' }}
            defaultValue={correct?.point}
            {...register('point')}
          />
          <Textarea
            minRows={3}
            placeholder="コメント、目標など(最大200文字前後)"
            style={{ width: '100%' }}
            defaultValue={correct?.comment}
            {...register('comment')}
          />
          <TextField label="目標の作家さん等" defaultValue={correct?.target} {...register('target')} />

          <Typography variant="body1">添削希望のイラスト(psd,jpg,png 150MB以内)</Typography>
          <input
            required
            {...register('image', {
              required: '必須です',
              validate: (files) =>
                files[0]?.size > 150 * 1024 * 1024 ? '添削用の画像は150MB以内でお願いします。' : undefined,
            })}
            type="file"
            accept=".png, .jpg, .jpeg, .psd"
          />
          <ErrorMessage errors={errors} name="image" as="p" style={{ color: 'red' }} />
          <Button color="primary" variant="contained" size="large" type="submit" disabled={isLoading}>
            {isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary',
                }}
              />
            ) : (
              '送信'
            )}
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default App;
