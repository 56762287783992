import { useEffect, useMemo } from 'react';
import { GoogleAuthResponse } from 'shared';
import { useAxiosGet } from './useGet';
const useGoogleAuth = () => {
  const { response, getData } = useAxiosGet<GoogleAuthResponse>('auth/google-auth');
  const user = useMemo(() => {
    if (response) return response.user;
    return '';
  }, [response]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (response) {
      if (!response.result) {
        window.location.href = response.authUrl;
      }
    }
  }, [response]);

  return { user };
};

export default useGoogleAuth;
