import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RealTimeCorrectionPage from './pages/RealTimeCorrectionPage';
import RealTimeEditCorrectionPage from './pages/RealTimeEditCorrectionPage';
import RegistUserPage from './pages/RegistUserPage';
import AdminLoginPage from './pages/AdminLoginPage';
import ShowUsersPage from './pages/ShowUsersPage';
import BenefitCorrectionPage from './pages/BenefitCorrectionPage';
import ProtectedRoute from './components/ProtectedRoute';
import BenefitCorrectionEditPage from './pages/BenefitCorrectionEditPage';
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RealTimeCorrectionPage />} />
        <Route path="/realtime/edit/:param" element={<RealTimeEditCorrectionPage />} />
        <Route path="/benefit" element={<BenefitCorrectionPage />} />
        <Route path="/benefit/edit/:param" element={<BenefitCorrectionEditPage />} />
        <Route path="/regist" element={<RegistUserPage />} />
        <Route path="/auth" element={<AdminLoginPage />} />
        {/* 認証が必要なページ */}
        <Route element={<ProtectedRoute />}>
          <Route path="/manage" element={<ShowUsersPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
