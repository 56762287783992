import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Stack, TextField, Typography, CircularProgress, Link } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useAxiosPost } from '../hooks/usePost';
import { RegistUserResponse } from 'shared';

const App: React.FC<{}> = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const { status, response, postData } = useAxiosPost<RegistUserResponse>('/users/create');

  useEffect(() => {
    setSubmitting(false);
  }, [status]);

  const onSubmit = useCallback(
    (data: any) => {
      setSubmitting(true);
      postData(data);
    },
    [postData],
  );

  if (status === 'error') {
    return (
      <>
        <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
          <Typography variant="body1">
            申し込み中にエラーが発生しました。お手数をお掛けしますが竹花塾Discodeサーバーか
            <Link href="https://twitter.com/29_Nom">こちら</Link>までお問い合わせください。
          </Typography>
        </Container>
      </>
    );
  }

  if (status === 'success') {
    return (
      <>
        <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
          {response?.result ? (
            <>
              <Typography variant="body1">申し込みを受け付けました。</Typography>
              <Typography variant="body1">名前: {response.data.name}</Typography>
              <Typography variant="body1">メールアドレス: {response.data.account}</Typography>
              <Typography variant="body1">
                訂正が必要な場合は竹花塾Discodeサーバーか
                <Link href="https://twitter.com/29_Nom">こちら</Link>までお問い合わせください。
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body1">{response?.message}</Typography>
              <Typography variant="body1">
                訂正が必要な場合は竹花塾Discodeサーバーか
                <Link href="https://twitter.com/29_Nom">こちら</Link>までお問い合わせください。
              </Typography>
            </>
          )}
        </Container>
      </>
    );
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Typography variant="h4" component="h4">
              FANBOX継続特典受付
            </Typography>
            <Typography variant="body1">
              竹花ノートFANBOXの継続特典対象者の受付フォームです。必ずFANBOXと同じ名前で登録してください。
              特典の添削受付は別途メールでご案内します。
              <Link href="https://take-note.fanbox.cc/">https://take-note.fanbox.cc/</Link>
            </Typography>
            <TextField required label="名前" {...register('name', { required: '必須です', minLength: 1 })} />
            <TextField
              required
              label="連絡先メールアドレス"
              {...register('account', { required: '必須です', minLength: 1 })}
            />
            <ErrorMessage errors={errors} name="image" as="p" style={{ color: 'red' }} />
            <Button color="primary" variant="contained" size="large" type="submit" disabled={isSubmitting}>
              {status === 'loading' ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'primary',
                  }}
                />
              ) : (
                '送信'
              )}
            </Button>
          </Stack>
        </form>
      </Container>
    </>
  );
};

export default App;
