import React, { useCallback } from 'react';

import { useAxiosPost } from '../hooks/usePost';
import { CorrctResponse } from 'shared';
import Submited from '../components/Submited';
import RealTimeForm from '../components/RealTimeForm';

const App: React.FC<{}> = () => {
  const { postFileData, status, response } = useAxiosPost<CorrctResponse>('/product/create');
  const onSubmit = useCallback(
    (data: any) => {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key !== 'card' && key !== 'image') {
          formData.append(key, data[key]);
        } else {
          formData.append(key, data[key][0]);
        }
      });

      postFileData(formData);
    },
    [postFileData],
  );

  if (['success', 'error'].includes(status)) {
    return <Submited status={status} editUrl={response?.editUrl || ''} />;
  } else {
    return <RealTimeForm onSubmit={onSubmit} isLoading={status === 'loading'} correct={undefined} />;
  }
};

export default App;
