import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { useAxiosPost } from '../hooks/usePost';
import { useAxiosGet } from '../hooks/useGet';
import { CorrctDataResponse, CorrctResponse } from 'shared';
import Submited from '../components/Submited';
import RealTimeForm from '../components/RealTimeForm';

const App: React.FC<{}> = () => {
  const { param } = useParams<{ param: string }>();
  const postCorrect = useAxiosPost<CorrctResponse>('/product/realtime/edit/' + param);
  const getCorrect = useAxiosGet<CorrctDataResponse>('/product/realtime/' + param);

  useEffect(() => {
    getCorrect.getData();
  }, []);

  const onSubmit = useCallback(
    (data: any) => {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key !== 'card' && key !== 'image') {
          formData.append(key, data[key]);
        } else {
          formData.append(key, data[key][0]);
        }
      });

      postCorrect.postFileData(formData);
    },
    [postCorrect],
  );

  if (!getCorrect.response) {
    return null;
  }

  if (!getCorrect.response.result) {
    return (
      <>
        <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
          <Typography variant="body1">{getCorrect.response.message}</Typography>
        </Container>
      </>
    );
  }

  if (['success', 'error'].includes(postCorrect.status)) {
    return <Submited status={postCorrect.status} editUrl={postCorrect.response?.editUrl || ''} />;
  } else {
    return (
      <RealTimeForm
        onSubmit={onSubmit}
        isLoading={postCorrect.status === 'loading'}
        correct={getCorrect.response.data}
      />
    );
  }
};

export default App;
