import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  CircularProgress,
  Box,
  Button,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableSortLabel,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { User, PLANS, StatusType, getStatusLabel } from 'shared';
import useGoogleAuth from '../hooks/useGoogleAuth';

import ReturnImageForm from '../components/RetuenImageForm';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useGetUsers from '../hooks/useGetUsers';
dayjs.extend(utc);
dayjs.extend(timezone);

const App: React.FC<{}> = () => {
  const { user } = useGoogleAuth();
  const { rows, error, success, resetMessage, updatePoint, sendMessage, sendReturnMessage } = useGetUsers();

  const [modalOpen, setModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const [selectedUser, setSelectedUser] = useState<User>();
  const [filteredRows, setFilteredRows] = useState<User[]>([]);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>('すべて');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof User>('id');

  useEffect(() => {
    if (error) {
      setAlertOpen(true);
      setModalOpen(false);
      return;
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      setAlertOpen(true);
      setModalOpen(false);
      return;
    }
  }, [success]);

  useEffect(() => {
    let sortedRows = [...rows];
    if (selectedStatus !== 'すべて') {
      sortedRows = sortedRows.filter((user) => user.correctStatus === selectedStatus);
    }
    if (orderBy === 'lastCorrectedAt') {
      sortedRows.sort((a, b) =>
        order === 'asc'
          ? new Date(a.lastCorrectedAt).getTime() - new Date(b.lastCorrectedAt).getTime()
          : new Date(b.lastCorrectedAt).getTime() - new Date(a.lastCorrectedAt).getTime(),
      );
    } else {
      sortedRows.sort((a, b) => {
        return order === 'asc'
          ? (a[orderBy] as number) - (b[orderBy] as number)
          : (b[orderBy] as number) - (a[orderBy] as number);
      });
    }
    setFilteredRows(sortedRows);
  }, [selectedStatus, rows, order, orderBy]);

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    },
    [rows],
  );

  const handleCloseAlert = useCallback(() => {
    setAlertOpen(false);
    resetMessage();
  }, []);

  const handleClick = useCallback(
    (id: number) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly number[] = selected;

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
    },
    [selected],
  );

  const handleClickPlanButton = useCallback(
    (id: number, point: number) => {
      updatePoint.postData({ id, point });
    },
    [updatePoint],
  );

  const handleClickInformButton = useCallback(
    (id: number) => {
      sendMessage.postData({ user_id: id });
    },
    [sendMessage],
  );

  const handleClickReturnButton = useCallback((user: User) => {
    setSelectedUser(user);
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setSelectedUser(undefined);
    setModalOpen(false);
    resetMessage();
  }, []);

  const handleClickSenRreturnButton = useCallback(
    (data: { [key: string]: any }) => {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key !== 'image') {
          formData.append(key, data[key]);
        } else {
          formData.append(key, data[key][0]);
        }
      });

      sendReturnMessage.postFileData(formData);
    },
    [sendReturnMessage],
  );

  const handleStatusChange = (event: { target: { value: string } }) => {
    setSelectedStatus(event.target.value as string);
  };

  const handleRequestSort = (property: keyof User) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (rows.length === 0) {
    return (
      <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10, justifyItems: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 5, marginBottom: 10, justifyItems: 'center' }}>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert
          onClose={handleCloseAlert}
          severity={error ? 'error' : 'success'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error ? error : success}
        </Alert>
      </Snackbar>
      {selectedUser && (
        <ReturnImageForm
          open={modalOpen}
          onClose={handleModalClose}
          user={selectedUser}
          error={sendReturnMessage.response?.message}
          onSubmit={handleClickSenRreturnButton}
          status={sendReturnMessage.status}
        />
      )}
      <Box sx={{ width: '100%', textAlign: 'right' }}>{user}</Box>
      <Box sx={{ width: '100%', justifyContent: 'flex-start' }}>
        <FormControl sx={{ minWidth: 200, mb: 2 }}>
          <InputLabel>ステータス</InputLabel>
          <Select value={selectedStatus} onChange={handleStatusChange}>
            <MenuItem value="すべて">すべて</MenuItem>
            {Object.values(StatusType).map((status) => (
              <MenuItem key={status} value={status}>
                {getStatusLabel(status)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredRows.map((row, index) => {
              const isItemSelected = selected.includes(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      onClick={() => handleClick(row.id)}
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell component="th" id={labelId} scope="row" padding="none">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.account}</TableCell>
                  <TableCell align="left">{getStatusLabel(row.correctStatus)}</TableCell>
                  <TableCell align="center">{row.totalPoint}</TableCell>
                  <TableCell align="center">{row.remainingTimes}</TableCell>
                  <TableCell align="center">{row.correctTimes}</TableCell>
                  <TableCell align="center">
                    {row.lastCorrectedAt
                      ? dayjs.utc(row.lastCorrectedAt).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')
                      : ''}
                  </TableCell>
                  <TableCell align="center">
                    {row.lastAddAt ? dayjs.utc(row.lastAddAt).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss') : ''}
                  </TableCell>
                  <TableCell align="left">
                    <ActionButtons
                      user={row}
                      onClickPlan={handleClickPlanButton}
                      onClickSendMail={handleClickInformButton}
                      onClickReturn={handleClickReturnButton}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default App;

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRequestSort: (property: keyof User) => void;
  rowCount: number;
  order: 'asc' | 'desc';
  orderBy: keyof User;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount, order, orderBy, onRequestSort } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        <TableCell sx={{ fontWeight: orderBy === 'id' ? 'bold' : 'normal' }}>
          <TableSortLabel
            active={orderBy === 'id'}
            direction={orderBy === 'id' ? order : 'asc'}
            onClick={() => onRequestSort('id')}
          >
            id
          </TableSortLabel>
        </TableCell>
        <TableCell>名前</TableCell>
        <TableCell>メール</TableCell>
        <TableCell>ステータス</TableCell>
        <TableCell sx={{ fontWeight: orderBy === 'totalPoint' ? 'bold' : 'normal' }}>
          <TableSortLabel
            active={orderBy === 'totalPoint'}
            direction={orderBy === 'totalPoint' ? order : 'asc'}
            onClick={() => onRequestSort('totalPoint')}
          >
            累計pt
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ fontWeight: orderBy === 'remainingTimes' ? 'bold' : 'normal' }}>
          <TableSortLabel
            active={orderBy === 'remainingTimes'}
            direction={orderBy === 'remainingTimes' ? order : 'asc'}
            onClick={() => onRequestSort('remainingTimes')}
          >
            添削可能回数
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ fontWeight: orderBy === 'correctTimes' ? 'bold' : 'normal' }}>
          <TableSortLabel
            active={orderBy === 'correctTimes'}
            direction={orderBy === 'correctTimes' ? order : 'asc'}
            onClick={() => onRequestSort('correctTimes')}
          >
            累計添削回数
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ fontWeight: orderBy === 'lastCorrectedAt' ? 'bold' : 'normal' }}>
          <TableSortLabel
            active={orderBy === 'lastCorrectedAt'}
            direction={orderBy === 'lastCorrectedAt' ? order : 'asc'}
            onClick={() => onRequestSort('lastCorrectedAt')}
          >
            最終添削日時
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ fontWeight: orderBy === 'lastAddAt' ? 'bold' : 'normal' }}>
          <TableSortLabel
            active={orderBy === 'lastAddAt'}
            direction={orderBy === 'lastAddAt' ? order : 'asc'}
            onClick={() => onRequestSort('lastAddAt')}
          >
            最終加算日時
          </TableSortLabel>
        </TableCell>
        <TableCell>操作</TableCell>
      </TableRow>
    </TableHead>
  );
}

interface ActionButtonsProps {
  user: User;
  onClickPlan: (id: number, point: number) => void;
  onClickSendMail: (id: number) => void;
  onClickReturn: (user: User) => void;
}

function ActionButtons(props: ActionButtonsProps) {
  const { onClickPlan, user, onClickSendMail, onClickReturn } = props;

  const isShowEmailButton = user.remainingTimes >= 1 && user.correctStatus === StatusType.NONE;
  const isShowrResendEmailButton = user.remainingTimes >= 1 && user.correctStatus === StatusType.INFORMED;
  const isShowRetuenButton = user.correctStatus === StatusType.APPLIED;

  return (
    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
      {Object.values(PLANS).map((plan, index) => (
        <Button
          sx={{ width: '1rem', fontSize: '0.75rem' }}
          key={index}
          variant="contained"
          color="primary"
          onClick={() => onClickPlan(user.id, plan.points)}
        >
          {plan.name}
        </Button>
      ))}
      {isShowEmailButton && (
        <Button
          sx={{ width: '1rem', fontSize: '0.75rem' }}
          variant="contained"
          color="success"
          onClick={() => onClickSendMail(user.id)}
        >
          添削案内
        </Button>
      )}
      {isShowrResendEmailButton && (
        <Button
          sx={{ width: '1rem', fontSize: '0.75rem' }}
          variant="contained"
          color="success"
          onClick={() => onClickSendMail(user.id)}
        >
          再送
        </Button>
      )}
      {isShowRetuenButton && (
        <Button
          sx={{ width: '1rem', fontSize: '0.75rem' }}
          variant="contained"
          color="error"
          onClick={() => onClickReturn(user)}
        >
          返却
        </Button>
      )}
    </Box>
  );
}
