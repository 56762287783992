"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStatusLabel = exports.StatusType = void 0;
var StatusType;
(function (StatusType) {
    StatusType["NONE"] = "none";
    StatusType["INFORMED"] = "informed";
    StatusType["APPLIED"] = "applied";
})(StatusType || (exports.StatusType = StatusType = {}));
// ステータスに対応するラベルを定義
var statusLabels = (_a = {},
    _a[StatusType.NONE] = "未応募",
    _a[StatusType.INFORMED] = "案内済み",
    _a[StatusType.APPLIED] = "添削待ち",
    _a);
// ステータスのラベルを取得する関数
var getStatusLabel = function (status) {
    return statusLabels[status] || "";
};
exports.getStatusLabel = getStatusLabel;
