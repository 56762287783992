import { useState, useCallback } from 'react';
import useAxios from '../hooks/useAxios';
import { isAxiosError } from 'axios';

export const useAxiosGet = <T>(url: string) => {
  const [status, setStatus] = useState('idle'); // idle, loading, success, error
  const [response, setResponse] = useState<T>();
  const [error, setError] = useState('');
  const { axiosClient, updateToken, token } = useAxios();

  const getData = useCallback(async () => {
    setStatus('loading');
    setError('');

    try {
      const res = await axiosClient.get(url);
      setResponse(res.data);
      setStatus('success');
    } catch (err) {
      if (isAxiosError(err)) {
        setError(err.response?.data.message);
      } else {
        setError('エラーが発生しました');
      }

      setStatus('error');
    }
  }, [url, axiosClient]);

  return { status, response, error, getData, updateToken, token };
};
