import React from 'react';
import { Modal, Container, Button, Stack, Typography, TextField, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Textarea from '@mui/joy/Textarea';
import { User } from 'shared';
interface UploadImageModalProps {
  open: boolean;
  onClose: () => void;
  user: User;
  onSubmit: (data: any) => void;
  error?: string;
  status: string;
}

const App: React.FC<UploadImageModalProps> = ({ open, onClose, user, onSubmit, error, status }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Modal open={open} onClose={onClose}>
      <Container maxWidth="sm" sx={{ top: 5, marginTop: 20, backgroundColor: 'white', padding: 5 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <input hidden value={user.id} {...register('user_id')} />
            <TextField disabled label="名前" defaultValue={user.name} />
            <Textarea minRows={3} placeholder="一言コメント(任意)" style={{ width: '100%' }} {...register('comment')} />
            <input
              required
              {...register('image', {
                required: '必須です',
                validate: (files) =>
                  files[0]?.size > 150 * 1024 * 1024 ? '画像は150MB以内でお願いします。' : undefined,
              })}
              type="file"
              accept=".png, .jpg, .jpeg, .psd"
            />
            <ErrorMessage errors={errors} name="image" as="p" style={{ color: 'red' }} />
            <Typography style={{ color: 'red' }}>{error}</Typography>
            <Button color="primary" variant="contained" size="large" type="submit" disabled={status === 'loading'}>
              {status === 'loading' ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'primary',
                  }}
                />
              ) : (
                '返却'
              )}
            </Button>
          </Stack>
        </form>
      </Container>
    </Modal>
  );
};

export default App;
