import React, { useEffect } from 'react';
import { auth } from '../util/firebase';
import { signInWithPopup } from 'firebase/auth';
import { Response } from 'shared';
import { Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAxiosPost } from '../hooks/usePost';

const App: React.FC<{}> = () => {
  const { status, error, response, postData, updateToken } = useAxiosPost<Response>('/auth');
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'success') {
      if (response) {
        if (response.result) navigate('/manage');
      } else {
        auth.signout(auth.auth);
      }
    }
    if (status === 'error') {
      auth.signout(auth.auth);
    }
  }, [response, navigate, status]);

  const handleLogin = async () => {
    const result = await signInWithPopup(auth.auth, auth.provider);
    const token = await result.user.getIdToken();
    updateToken(token);
    // バックエンドにトークンを送信して検証
    postData({});
  };

  return (
    <>
      <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10, justifyItems: 'center' }}>
        <Button variant="contained" onClick={handleLogin}>
          管理者ログイン
        </Button>
        <Typography style={{ color: 'red' }}>{error}</Typography>
      </Container>
    </>
  );
};

export default App;
