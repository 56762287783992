import React from 'react';
import { Container, Typography, Link } from '@mui/material';

interface EnterFormProps {
  editUrl: string;
  status: string;
}
const App: React.FC<EnterFormProps> = ({ editUrl, status }) => {
  if (status === 'error') {
    return (
      <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
        <Typography variant="body1">
          申し込み中にエラーが発生しました。お手数をお掛けしますが竹花塾Discodeサーバーか
          <Link href="https://twitter.com/29_Nom">こちら</Link>までお問い合わせください。
        </Typography>
      </Container>
    );
  }
  return (
    <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
      <Typography variant="body1">
        申し込みを受け付けました。 添削受付状況は
        <Link href="https://docs.google.com/spreadsheets/d/17wm3ZfOVIJ0wDWLGGlYajV4JSvVkBHkDhhyZGx6E-Jw/edit#gid=0">
          こちら
        </Link>
        から確認いただけます。
      </Typography>
      <Typography variant="body1">
        差し替えは以下のURLから行えます。あらかじめメモかブックマークをしてご利用ください。
      </Typography>
      <Link href={editUrl}>{editUrl}</Link>
      <Typography variant="body1">
        添削受付状況シートに反映されない場合は竹花塾Discodeサーバーか
        <Link href="https://twitter.com/29_Nom">こちら</Link>までお問い合わせください。
      </Typography>
    </Container>
  );
};

export default App;
