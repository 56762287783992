import { useAxiosGet } from './useGet';
import { useCallback, useEffect, useState } from 'react';
import { ShowUserResponse, User } from 'shared';
import useUpdatePoint from './useUpdatePoint';
import useSendCorrectMessage from './useSendInformMail';
import useSendReturnMessage from './useSendReturnMail';

const useGetUsers = () => {
  const { getData, response, status } = useAxiosGet<ShowUserResponse>('/users/show');
  const updatePoint = useUpdatePoint();
  const sendMessage = useSendCorrectMessage();
  const sendReturnMessage = useSendReturnMessage();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [rows, setRow] = useState<User[]>([]);

  useEffect(() => {
    if (status === 'idle') {
      getData();
    }
  }, [status, getData]);

  useEffect(() => {
    if (response) {
      setRow(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (updatePoint.error) {
      setError(updatePoint.error);
      return;
    }
    if (updatePoint.response) {
      const user = updatePoint.response.data;
      setRow((prevRows) => prevRows.map((u) => (u.id === user.id ? user : u)));
    }
  }, [updatePoint.response, updatePoint.error]);

  useEffect(() => {
    if (sendMessage.error) {
      setError(sendMessage.error);
      return;
    }
    if (sendMessage.response) {
      const user = sendMessage.response.data;
      setRow((prevRows) => prevRows.map((u) => (u.id === user.id ? user : u)));
      setSuccess('添削案内メールを送りました');
    }
  }, [sendMessage.response, sendMessage.error]);

  useEffect(() => {
    if (sendReturnMessage.error) {
      setError(sendReturnMessage.error);

      return;
    }
    if (sendReturnMessage.response) {
      const user = sendReturnMessage.response.data;
      if (user) {
        setRow((prevRows) => prevRows.map((u) => (u.id === user.id ? user : u)));
        setSuccess('添削返却メールを送りました');
      }
    }
  }, [sendReturnMessage.response, sendReturnMessage.error]);

  const resetMessage = useCallback(() => {
    setSuccess('');
    setError('');
  }, []);

  return { status, rows, error, success, resetMessage, updatePoint, sendMessage, sendReturnMessage };
};

export default useGetUsers;
