import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

const useAxios = () => {
  const tokenRef = useRef<string>();
  const [token, setToken] = useState<string | null>(null);
  const client = useRef(axiosClient);
  const navigate = useNavigate();

  useEffect(() => {
    // リクエストインターセプターで動的にヘッダーを追加
    client.current.interceptors.request.use(
      (config) => {
        if (tokenRef.current) {
          config.headers['Authorization'] = 'Bearer ' + tokenRef.current;
        } else {
          config.headers['Authorization'] = '';
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  }, []);

  useEffect(() => {
    // リクエストインターセプターで動的にヘッダーを追加
    client.current.interceptors.response.use(
      (response) => {
        console.log(response);
        return response;
      }, // 成功時はそのままレスポンスを返す
      (error) => {
        console.log(error);
        if (error.response && error.response.status === 403) {
          // 403エラーの場合はログインページ
          navigate('/auth');
        }
        return Promise.reject(error); // エラーをそのまま返す
      },
    );
  }, [navigate]);

  // ヘッダーを更新する関数
  const updateToken = useCallback((token: string) => {
    tokenRef.current = token;
    setToken(token);
  }, []);

  return { axiosClient: client.current, updateToken, token };
};

export default useAxios;
