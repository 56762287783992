import React from 'react';
import { Button, Container, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';

interface EnterFormProps {
  onSubmit: (data: any) => void;
  error?: string;
}
const App: React.FC<EnterFormProps> = ({ onSubmit, error }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Typography variant="h4" component="h4">
              継続特典がっつり添削応募フォーム
            </Typography>
            <Typography variant="body1">
              継続特典がっつり添削応募受付フォームです。案内に記載された受付番号を入力してください。
            </Typography>
            <TextField required label="受付番号" {...register('number', { required: '必須です', minLength: 1 })} />
            <ErrorMessage errors={errors} name="image" as="p" style={{ color: 'red' }} />
            <Typography style={{ color: 'red' }}>{error}</Typography>
            <Button color="primary" variant="contained" size="large" type="submit">
              確認
            </Button>
          </Stack>
        </form>
      </Container>
    </>
  );
};

export default App;
