import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../util/firebase';
import useAxios from '../hooks/useAxios';
import { Container, CircularProgress } from '@mui/material';

const App: React.FC<{}> = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { updateToken } = useAxios();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth.auth, async (currentUser) => {
      if (currentUser) {
        const result = await currentUser.getIdTokenResult();
        const isAdmin = !!result.claims.admin;
        updateToken(isAdmin ? result.token : '');
        setIsAuthenticated(isAdmin);
      } else {
        updateToken('');
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    return () => unsubscribe(); // クリーンアップ
  }, [updateToken]);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10, justifyItems: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }
  return <>{isAuthenticated ? <Outlet /> : <Navigate to="/auth" />}</>;
};

export default App;
